<div class="loader-sell360">
    <div class="loader">
        <span class="box-icon"></span>
        <span class="box-icon"></span>
        <div class="loader-img">
            <img src="assets/images/sell-360-logo.svg" alt="">
        </div>
        <span class="txt"><span></span>LOADING...</span>
    </div>
</div>
