<div class="_add-product">
  <div class="row AP_heading">
    <div class="addItemCell PL-5">Product Name</div>
    <div class="addItemCell unit-w">Unit</div>

    <div class="addItemCell2">QTY.</div>
    <div class="addItemCell2">T.P</div>
    <div class="addItemCell2">T.O</div>
    <div class="addItemCell2">T. Dis. (%)</div>
    <div class="addItemCell2">S. Disc.</div>
    <div class="addItemCell2">E. Disc.</div>
    <div class="addItemCell2">Tax</div>
    <div class="addItemCell3">Net Amount</div>
  </div>

  <div
    class="row _row-product"
    *ngFor="let item of order.items; let index = index"
  >
    <a class="dd-handle dd3-handle swap-div"></a>

    <div class="col-12 p-0">
      <div class="addItemCell PL-5">
        <div class="_emp-sele _product-name">
          {{ item.itemName }}
        </div>
      </div>

      <div class="addItemCell unit-w">
        <div class="addItemCell2 _h25">
          {{ item.unitName }}
          <!-- <ng-select [multiple]="false" class="formselect"
                                  [(ngModel)]="product.pref_id" (change)="setPrefrence($event, product)">
                                  <ng-option [value]="null" disabled>Select Unit</ng-option>
                                  <ng-option *ngFor="let unit of product.units" value="{{unit.pref_id}}">
                                      {{unit.unit_name}}</ng-option>
                              </ng-select> -->
        </div>
      </div>

      <div class="addItemCell2 _h25">
        <input
          class="addItemCell2_input"
          [(ngModel)]="item.quantity"
          type="number"
          min="1"
          style="font-size: 13px"
        />
        {{ item.schemeFreeProdsCount ? "+" + item.schemeFreeProdsCount : "" }}
      </div>
      <div class="addItemCell2 _h25">
        {{ item.tradePrice | number : "1.2-2" }}
      </div>
      <div class="addItemCell2 _h25">
        {{ item.tradeOffer | number : "1.2-2" }}
      </div>
      <div class="addItemCell2 _h25">
        {{ item.tradeDiscount || 0 }}% ({{
          item.total_trade_discount_pkr || 0 | number : "1.2-2"
        }})
      </div>
      <div class="addItemCell2 _h25">
        {{ item.specialDiscount || 0.0 | number : "1.2-2" }}
      </div>
      <div class="addItemCell2 _h25">
        <input
          class="addItemCell2_input"
          type="number"
          placeholder="0"
          min="0"
          max="{{ item.maxBookerDiscount }}"
          [(ngModel)]="item.booker_discount"
          style="font-size: 13px"
        />
      </div>
      <div class="addItemCell2 _h25">
        {{ item.tax | number : "1.2-2" }}
      </div>
      <div class="addItemCell3 _h25">
        {{ item.totalBill | number : "1.2-2" }}
      </div>
      <a
        class="btn _order-del"
        title="Delete"
        (click)="deleteOrderItem(item.itemId)"
        ><i class="fa fa-trash-alt"></i
      ></a>
    </div>
    <div
      *ngFor="let compProd of item.comlimentoryProds"
      class="col-12 pl-5 pr-5"
    >
      <div
        style="
          background-color: #e9f7e4;
          font-size: 12px;
          border: 0.063rem solid #8cd772;
          margin-top: 8px;
          padding: 3px 8px;
          line-height: 1;
          color: #16872e;
        "
      >
        <div class="row">
          <div class="col-md-6">
            <strong>Free Product:</strong> {{ compProd.item_name }}
          </div>
          <div class="col-md-6 text-right">
            <strong>QTY: </strong> {{ compProd.quantity }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row _row-product" *ngIf="!order.totalItemsCount">
    <h6 class="no-data">Add products to counter sale...</h6>
  </div> -->

  <div class="row _totalBar _totalBar2">
    <div class="addItemCell _h25 PL-5">Total Items</div>
    <div class="addItemCell _h25 unit-w">
      <!-- {{ order.totalItemsCount }} -->
    </div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25 width-t">Gross Amount:</div>
    <div class="addItemCell3 _h25">
      {{ order.grossPrice | number : "1.2-2" }}
    </div>
  </div>

  <div class="row _totalBar2">
    <div class="addItemCell _h25 PL-5"></div>
    <div class="addItemCell _h25 unit-w"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25 width-t">Trade Offer:</div>
    <div class="addItemCell3 _h25">
      {{ order.totalTradeOffer | number : "1.2-2" }}
    </div>
  </div>

  <div class="row _totalBar2">
    <div class="addItemCell _h25 PL-5"></div>
    <div class="addItemCell _h25 unit-w"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25 width-t">Trade Discount:</div>
    <div class="addItemCell3 _h25">
      {{ order.totalTradeDiscount | number : "1.2-2" }}
    </div>
  </div>

  <div class="row _totalBar2">
    <div class="addItemCell _h25 PL-5"></div>
    <div class="addItemCell _h25 unit-w"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25 width-t">Special Discount:</div>

    <div class="addItemCell3 _h25">
      {{ order.specialDiscount | number : "1.2-2" }}
    </div>
  </div>

  <div class="row _totalBar2">
    <div class="addItemCell _h25 PL-5"></div>
    <div class="addItemCell _h25 unit-w"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25 width-t">Extra Discount:</div>

    <div class="addItemCell3 _h25">
      {{ order.bookerOrExtraDiscount | number : "1.2-2" }}
    </div>
  </div>

  <div class="row _totalBar2">
    <div class="addItemCell _h25 PL-5"></div>
    <div class="addItemCell _h25 unit-w"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25 width-t">Tax:</div>

    <div class="addItemCell3 _h25">
      {{ order.tax | number : "1.2-2" }}
    </div>
  </div>

  <div class="row _totalBar2">
    <div class="addItemCell _h25 PL-5"></div>
    <div class="addItemCell _h25 unit-w"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25 width-t">
      <strong>Total Amount Due:</strong>
    </div>

    <div class="addItemCell3 _h25" style="font-size: 18px; color: #0038ba">
      {{ order.totalBill | number : "1.2-2" }}
    </div>
  </div>
  <div class="row _totalBar2">
    <div class="addItemCell _h25 PL-5"></div>
    <div class="addItemCell _h25 unit-w"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25 width-t">
      <strong>Cash:</strong>
    </div>

    <div class="addItemCell3 _h25" style="font-size: 18px; color: #0038ba">
      {{ order.totalCash | number : "1.2-2" }}
    </div>
  </div>
  <div class="row _totalBar2">
    <div class="addItemCell _h25 PL-5"></div>
    <div class="addItemCell _h25 unit-w"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25 width-t">
      <strong>Cheque Payment:</strong>
    </div>

    <div class="addItemCell3 _h25" style="font-size: 18px; color: #0038ba">
      {{ order.checkPayment || 0 | number : "1.2-2" }}
    </div>
  </div>
  <div class="row _totalBar2">
    <div class="addItemCell _h25 PL-5"></div>
    <div class="addItemCell _h25 unit-w"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25"></div>
    <div class="addItemCell2 _h25 width-t">
      <strong>Credited Amount:</strong>
    </div>

    <div class="addItemCell3 _h25" style="font-size: 18px; color: #0038ba">
      {{ order.creditPayment || 0 | number : "1.2-2" }}
    </div>
  </div>
  <!-- <div class="row _notesTER">
                  <div class="col-md-12">
                      Notes
                      <textarea class="textarea-NOTES" rows='1' name="notes-input" [(ngModel)]="notes"
                          placeholder="Enter Notes" style="font-size: 13px"></textarea>
                  </div>
              </div> -->
</div>
